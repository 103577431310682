.button {
  border: none;
  background: transparent;
  position: relative;
  padding: 10px 10px 10px 35px;
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-gray-normal);

  &__icon {
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -11px;
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
