@import "../../styles/sccs-vars.scss";

.headline-group {
  margin: 0 0 60px;

  @media (max-width: $until-sm) {
    margin: 30px 0;
  }

  &--secondary {
    margin: 10px 0;

    @media (max-width: $until-sm) {
      margin: 5px 0;
    }
  }
}
