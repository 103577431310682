@import "../../styles/sccs-vars.scss";

.branding {
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  transition: opacity 450ms cubic-bezier(0.24, 1.12, 0.71, 0.98);
  opacity: 1;

  &__backlink {
    flex: 0 0 auto;
  }

  &__logo {
    flex: 0 0 200px;

    & img {
      display: inline-block;
    }
  }

  &__content {
    flex: 0 1 auto;
  }

  &.is-hidden {
    opacity: 0;
  }

  @media (max-width: $until-sm) {
    &--footer {
      display: block;
      text-align: center;

      .branding__logo {
        margin: 0 0 15px;
      }
    }
  }

  @media (max-width: $until-xl) {
    margin: 15px 0 0;
  }
}
