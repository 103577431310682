@import "../../styles/sccs-vars.scss";

.footer-end {
  font-size: 12px;
  color: var(--color-gray-normal);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10px 0;

  & a {
    color: var(--color-gray-normal);
  }

  &__in-sidebar {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
  }

  @media (max-width: $until-sm) {
    flex-direction: column;
    align-items: center;
    margin: 20px 0 0;
  }
}

.footer-social {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  &__label {
    margin: 0 10px 0 0;
  }
}
