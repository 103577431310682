@import "../../styles/sccs-vars.scss";

.nav {
  white-space: nowrap;

  &__item {
    display: inline-flex;
    position: relative;

    & + .nav__item {
      margin-left: 20px;
    }
  }

  &__search {
    position: absolute;
    right: 0;
    top: 50%;
    visibility: hidden;
    overflow: hidden;
    width: 0;
    margin-top: -25px;
    opacity: 0.8;
    transition: width 400ms cubic-bezier(0.24, 1.12, 0.71, 0.98) 100ms,
      visibility 0s linear 500ms, opacity 200ms linear;

    &.is-open {
      opacity: 1;
      width: 600px;
      visibility: visible;
      transition-delay: 0s;
    }
  }

  &--relations {
    @media (max-width: $until-md) {
      .nav__item {
        display: block;
        margin: 5px 0 0;
      }
    }
  }
}
