.footer {
  border-top: 1px solid var(--color-gray-normal);
  transition: opacity 450ms cubic-bezier(0.24, 1.12, 0.71, 0.98) 1500ms;
  opacity: 1;
  backface-visibility: hidden;

  &.is-hidden {
    opacity: 0;
    transition-delay: 0s;
  }
}
