.icon-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  border: 0;
  background: none;
  color: var(--color-gray-normal);

  &--primary {
    color: var(--color-brand);
  }

  &--big {
    font-size: 16px;
  }

  &__icon {
    margin-right: 6px;
    position: relative;
    top: -1px;
  }

  &:hover {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 100%;
      border-bottom: 2px solid var(--color-gray-normal);
    }
  }
}
