@import "../../styles/sccs-vars.scss";

.split {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--align-top {
    align-items: flex-start;
  }

  &--overview {
    min-height: 40px;
  }

  &__left {
    flex: 0 1 auto;
  }

  &__right {
    flex: 0 1 auto;
  }

  @media (max-width: $until-sm) {
    display: block;
  }
}
