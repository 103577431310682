@import "../../styles/sccs-vars.scss";

.letter-index {
  margin-bottom: 60px;

  &__group {
    border-top: 1px solid var(--color-gray-normal);
    position: relative;
    padding: 0 0 0 200px;
    min-height: 80px;

    @media (max-width: $until-md) {
      padding: 0 0 0 50px;
    }
  }

  &__letter {
    font-size: 50px;
    line-height: 80px;
    color: var(--color-gray-normal);
    position: absolute;
    left: 50px;
    top: 0;

    @media (max-width: $until-md) {
      left: 0;
    }
  }
}
