.markdown {
  color: var(--color-gray-normal);
  font-size: 16px;

  p {
    margin: 20px 0;
  }

  a {
    color: var(--color-gray-normal);
    text-decoration: underline;
    &:hover {
      color: var(--color-gray-dark);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-gray-normal);
  }

  h1 {
    font-size: 2em;
    margin: 1em 0;
    font-weight: 300;
  }

  h2 {
    font-size: 1.5em;
    margin: 1em 0;
  }

  h3 {
    font-size: 1.2em;
    margin: 1em 0;
  }

  img {
    max-width: 100%;
    margin: 10 0 10 0;
  }

  pre {
    overflow-x: auto;
    padding: 10px;
    background: var(--color-gray-dark);
    -webkit-overflow-scrolling: touch;
  }
}
