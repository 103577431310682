@import "../../styles/sccs-vars.scss";

.hero-headline {
  font-size: 38px;
  font-weight: 300;
  line-height: 1.2;
  color: var(--color-white);
  padding: 0;
  margin: 0;

  &__alt {
    color: var(--color-gray-light);
  }

  &--inverse {
    color: var(--color-gray-light);
  }

  @media (max-width: $until-sm) {
    font-size: 26px;

    &__alt {
      display: block;
    }
  }
}
