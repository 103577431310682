.radar-grid {
    position: relative;
    margin-bottom: 50px;
    color: white;
    display: none;
}

@media screen and (min-width: 800px) {
    .radar-grid {
        display: block;
    }
}

.radar-grid .quadrant-label {
    position: absolute;
    width: 20%;
}

.quadrant-label .split {
    font-size: 12 px;
    text-transform: uppercase;
}

.quadrant-label hr {
    width: 100%;
    margin: 10px 0 10px 0;
}

.quadrant-label .description {
    font-size: 14px;
    color: #a6b1bb
}

.quadrant-label .icon-link {
    font-size:  12px;
}

.quadrant-label .icon-link .icon {
    background-size:  18px 18px;
    width: 18px;
    height: 18px;
}

.radar-grid .radar-legend {
    position: absolute;
    width: 15%;
    right: 0;
    top: 45%;
}

.radar-legend .wrapper {
    margin-bottom: 10px;
}

.radar-legend .icon {

    background-position: center;
    margin-right: 5px;
}