.item {
  padding: 10px;
  border-bottom: 1px solid var(--color-gray-normal);
  display: block;
  text-decoration: none;
  transition: background 200ms ease-out;
  color: var(--color-gray-normal);
  box-sizing: border-box;

  &.is-active {
    background: var(--color-gray-dark-alt);
  }

  &:hover {
    background: var(--color-gray-dark-alt2);
  }

  &:first-child {
    border-top: 1px solid var(--color-gray-normal);
  }

  &--big {
    min-height: 80px;
    padding: 20px 10px;
  }

  &--no-leading-border {
    &:first-child {
      border-top: none;
    }
  }

  &--no-trailing-border {
    &:last-child {
      border-bottom: none;
    }
  }

  &__title {
    font-size: 16px;
    color: var(--color-white);

    &.greyed-out {
      color: var(--color-gray-light-alt);
    }
  }

  &__info {
    margin-top: 5px;
    font-size: 12px;
    color: var(--color-gray-normal);
  }
}
