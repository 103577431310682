h4.headline {
  font-size: 18px;
}

.headline {
  margin: 0;
  padding: 0;
  color: var(--color-white);
  font-size: 20px;
  font-weight: normal;

  &--dark {
    color: var(--color-gray-light);
  }
}
