@import "../../styles/sccs-vars.scss";

.quadrant-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__quadrant {
    flex: 0 0 45%;
    margin-bottom: 40px;

    @media (max-width: $until-lg) {
      flex-basis: 100%;
    }
  }
}
