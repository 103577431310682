.ring-list {
  &__header {
    margin-bottom: 20px;
  }
  &__item {
    font-size: 13px;
    display: block;
    margin-bottom: 12px;
  }
}
