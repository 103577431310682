@import "../../styles/sccs-vars.scss";

.filter {
  margin-bottom: 40px;

  /* @todo: re-enable filter for mobile */
  @media (max-width: $until-sm) {
    display: none;
  }
}
